import React, { useState } from 'react';
import { Typography, Grid, Select, MenuItem } from "@mui/material";
import Genresgrouped from "./sorter/GenresGrouped.tsx";
import Subbed from "./sorter/Subbed.tsx";
import Sortby from "./sorter/SortBy.tsx";
import Search from "./sorter/Search";
import { useNavigate } from "react-router-dom";
import { selectSx, genreItemSx } from './subStyle';
import { MenuProps as MenuPropsType } from "@mui/material/Menu";

function Sorter({varCheck, inputHandler, location, setLimit, filters, setFilters, rCount}) {

    const vars = ['Title', 'Country']

    const navigate = useNavigate()
    
    const sorterConSx = {
        width: {xs: "100%", md: 960},
        justifyContent: "space-between", 
        height: {xs: 'auto', sm: 'auto', md: 40},
        m: {xs: "auto", sm: "0 auto"}
    }

    const sorterSx = {
        display: "flex",
        alignItems: "center",
        color: "info.main",
        width: {xs: "25vw", sm: 90, md: 120},
        maxWidth: 120,
        height: "100%",
        ml: "2px"
    }

    const MenuProps: Partial<MenuPropsType> = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
            },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
            },
        MenuListProps: {
            disablePadding: true
        },
        disableScrollLock: true,
        PaperProps: {
          style: {
            borderRadius: 0,
            boxShadow: "none",
            margin: "10px 0",
            maxHeight: "auto",
            color: "whitesmoke",
            // backgroundColor: "rgba(255,255,255,0.95)"
            backgroundColor: "rgba(31, 46, 54, 0.95)"
          },
        sx: {
            width: {xs: 150, sm: 200}
        }
        },
      };

         // FILTER & SORT

         const startPath = location.pathname.split("?")[0]

         let [queries, setQueries] = useState([])
         let newQuery = ''

        const getPath = (type, value) => {

                newQuery = type + '=' + value
                // console.log(queries, type)
                let newQueries = []
                if (value === '') {
                    newQueries = queries.filter(q => !q.includes(type))
                } else {
                    newQueries = queries.filter(q => !q.includes(type)) 
                    type === 'sort' && queries.join(',').includes(newQuery) && queries.join(',').includes('desc') ?
                    newQueries.push(newQuery + '&type=asc') :
                    type === 'sort' ?
                    newQueries.push(newQuery + '&type=desc') :
                    newQueries.push(newQuery)
                    // console.log(newQuery, newQueries)
                } 
                queries = newQueries
                setQueries(newQueries)

                let path
                queries.length === 0 ? path = startPath : path = startPath + '?' + queries.join('&')
                navigate(path)
                setFilters(path.split('?')[1])
                console.log(filters)
                setLimit(99)
            }

    return (
    //   <Typography component="span" variant="body2"> 
      <Grid container sx={{...sorterConSx}}>
            <Grid sx={{display: 'flex', justifyContent: "space-around", padding: {xs: '0 20px', sm: 0}, width: {xs: '100%', sm: 'auto'}, minHeight: '30px'}}>
                <Select name="sorter" defaultValue="Title" label="" sx={selectSx} MenuProps={MenuProps}>

                    {vars.map((v) => {
                        return (
                            <MenuItem 
                            onClick={() => varCheck(v)} 
                            key={v} 
                            value={v}
                            sx={{...genreItemSx}}
                            >{v}</MenuItem>
                        )
                    })}
                </Select>
                <Search inputHandler={inputHandler}/>
                <Grid sx={{m: 'auto 10px', fontSize: {xs: 11, md: 14}}}>{rCount} results</Grid>
            </Grid>
            
            <Grid sx={{display: 'flex', justifyContent: "space-around", m: {xs: '9px 0 0', sm: 0}, width: {xs: '100%', sm: 'auto'}, minHeight: '30px'}}>
                <Grid item  sx={{...sorterSx}}>
                    <Subbed getPath={getPath}/>
                </Grid>
                <Grid item  sx={{...sorterSx}}>
                    <Genresgrouped getPath={getPath}/>
                </Grid>
                <Grid item  sx={{...sorterSx}}>
                    <Sortby getPath={getPath}/>
                </Grid>
            </Grid>
      </Grid>

    //   </Typography>
    )
}

export default Sorter