import { useState } from "react"
import { Grid, Link, Modal } from "@mui/material"
import { onlLogoSx, torLogoSx } from "../dirStyle"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Downloadmobile = ({m, tr_names}) => {

  const subPath = '../subtitles/'

  const main = JSON.parse(m.maininfo)
  const release = JSON.parse(m.releaseinfo)
  const subs = JSON.parse(m.allsubs)
  const ids = JSON.parse(m.ids)

  const rutrPath = 'https://rutracker.org/forum/tracker.php?nm=' + main.imdb_title_exp + ' ' + release.prod_year
  const therarbgPath = 'https://therarbg.com/get-posts/keywords:tt' + main.imdb_id
  const bt4gPath = 'https://bt4gprx.com/search?q=' + main.imdb_title_exp + ' ' + release.prod_year + '&category=movie&orderby=seeders'
  const exttoPath = 'https://search.extto.com/search/?c=movies&q=' + main.imdb_title_exp + ' ' + release.prod_year
  const ytsPath = 'https://yts.mx/browse-movies/tt' + main.imdb_id

  const trackers = [
    {name: 'rutracker', path: rutrPath},
    {name: 'yts', path: ytsPath},
    {name: 'therarbg', path: therarbgPath},
    {name: 'bt4g', path: bt4gPath},
    {name: 'extto', path: exttoPath}
  ]

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const style = {
    position: 'absolute',
    display: "flex",
    margin: 'auto',
    justifyContent: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: "rgba(237, 230, 219, 0.40)",
    border: '4px solid white',
    boxShadow: 24,
    p: 4
  };

  subs.sort((a,b) => a.name.localeCompare(b.name))

    return (
      <Grid item sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'left'}}>

        <Grid component="img" src={"../logo/torr_logo_100.png"} title='Find torrent' onClick={handleOpen} sx={{...onlLogoSx, cursor: 'pointer' }} />
        <Modal
          open={open}
          onClose={handleClose}
        >
        <Grid sx={style}>
            {trackers.map(t => {
              return (
              <Link key={t.name} className="sublogo-mobile" href={t.path} target="_blank" rel='noopener noreferrer'>
                <Grid component="img" src={"../logo/" + t.name + "_100.png"} title={t.name} sx={torLogoSx} />
            </Link>
              )
            })}
        </Grid>
        </Modal>

        {subs.map(s => {

          let subinfo 
          s.translator ? subinfo = s.translator.replaceAll('_', ' ') + ' ' + s.s_type : subinfo = 'sub ' + s.lang + ' ' + s.s_type

          function getLogo() {

              let logo
              s.translator.includes('&') ? logo = "../translators/team_logo150.png" :
              tr_names.includes(s.translator) ? logo = "../translators/" + s.translator + "_logo150.png" :
              s.lang === 'rus' ? logo = "../logo/sub_logo_rus70.png" : logo = "../logo/sub_logo_eng70.png"
              
              return logo
          }

          const subLogo = getLogo()

          return (
            <Link className="onllogo" key={s.name} href={subPath + s.name} target="_blank" download>
                <Grid item sx={onlLogoSx} title={subinfo}>
                    <LazyLoadImage
                      className="stream"
                      effect="blur"
                      delayTime={100}
                      threshold={50}
                      placeholderSrc={subLogo}
                      src={subLogo}
                      height="100%"
                      width="100%"
                      style={{objectFit: "cover"}}
                      />
                </Grid>
            </Link>
          )
          }
          )}

      </Grid>
    );
}

export default Downloadmobile;
