import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from '@mui/material';
import { filterConSx, filterLabelSx, selectSx, genreBigItemSx, genreItemSx, firstItemSx } from '../../../theme';
import { MenuProps as MenuPropsType } from "@mui/material/Menu";

export default function Genresgrouped({getPath}) {

    const MenuProps: Partial<MenuPropsType> = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
            },
        transformOrigin: {
            vertical: "top",
            horizontal: "right"
            },
        MenuListProps: {
            disablePadding: true
        },
        disableScrollLock: true,
        PaperProps: {
          style: {
            borderRadius: 0,
            boxShadow: "none",
            margin: "10px 0",
            maxHeight: "auto",
            color: "whitesmoke",
        // backgroundColor: "rgba(255,255,255,0.95)"
            backgroundColor: "rgba(31, 46, 54, 0.95)"
          },
        sx: {
            width: {xs: 150, sm: 200}
        }
        },
      };

      const genresBig = [
        "Documentary",
        "Narrative"
    ]

      const genres = [
        "Drama",
        "Comedy",
        "Thriller",
        "Horror",
        "Animation",
        "Adventure",
        "Crime",
        "Biography",
        "Mystery",
        "Fantasy",
        "Sci-Fi",
        "History",
        "Music",
        "Action"
    ];


  return (
      <FormControl sx={{width: "100%", height: "100%", justifyContent: "flex-end"}}>
        <Grid sx={{...filterConSx}}>
        <InputLabel htmlFor="Genre" sx={{...filterLabelSx}}>Genre</InputLabel>
        <Select defaultValue=""  inputProps={{id: 'Genre'}}  sx={{...selectSx}} MenuProps={MenuProps}>
          
                <MenuItem 
                onClick={() => getPath('genre', '')} 
                value=""
                sx={{...firstItemSx}}
                > All </MenuItem>

                {genresBig.map((genre) => {
                    return (
                            <MenuItem 
                            onClick={() => getPath('genre', genre.toLowerCase())}
                            key={genre} 
                            value={genre}
                            sx={{...genreBigItemSx}}
                            >{genre}</MenuItem>
                    )
                })}

                {genres.map((genre) => {
                    return (
                            <MenuItem 
                            onClick={() => getPath('genre', genre.toLowerCase())}
                            key={genre} 
                            value={genre}
                            sx={{...genreItemSx}}
                            >{genre}</MenuItem>
                    )
                })}

        </Select>
        </Grid>
      </FormControl>


  );
}