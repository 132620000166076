import { useState, useEffect } from "react"
import { Grid, Link, Modal } from '@mui/material';
import { Header, onlLogoSx, StreamWrap } from '../movieStyle';
import axios from "axios"

const Downl = ({movie}) => {

    const main = JSON.parse(movie.maininfo)
    const release = JSON.parse(movie.releaseinfo)

    const subPath = '../subtitles/'
    
    const rutrPath = 'https://rutracker.org/forum/tracker.php?nm=' + main.imdb_title_exp + ' ' + release.prod_year
    const therarbgPath = 'https://therarbg.com/get-posts/keywords:tt' + main.imdb_id
    const bt4gPath = 'https://bt4gprx.com/search?q=' + main.imdb_title_exp + ' ' + release.prod_year + '&category=movie&orderby=seeders'
    const exttoPath = 'https://search.extto.com/search/?c=movies&q=' + main.imdb_title_exp + ' ' + release.prod_year
    const ytsPath = 'https://yts.mx/browse-movies/tt' + main.imdb_id
    const corrRutrPath = rutrPath
    .replace('ê', '%C3%AA')

    const trackers = [
      {name: 'rutracker', path: rutrPath},
      {name: 'yts', path: ytsPath},
      {name: 'therarbg', path: therarbgPath},
      {name: 'bt4g', path: bt4gPath},
      {name: 'extto', path: exttoPath}
    ]

    const translators = [
      "24fps",
      "Chacun_son_cinema",
      "Chacun_son_cinema_&_FOCS",
      "Cinemacoon",
      "Cinemacoon_&_Dark_Alice",
      "Cinemacoon_&_MENINSUB",
      "Cool_Story_Blog",
      "Dark_Alice",
      "Dark_Alice_&_KATYA",
      "Dark_Alice_&_Sergei_Kubryak",
      "Directors_Card",
      "FOCS",
      "FOCS_&_homoSUBiens",
      "FOCS_&_RebelProject",
      "Gyarandu",
      "homoSUBiens_&_one_inch_tall_&_RebelProject",
      "KATYA",
      "Kleinzeit",
      "MENINSUB",
      "one_inch_tall",
      "RebelProject",
      "Scandiscope",
      "serpentarium",
      "SubbedAsFuck",
      "Timur_Bokov",
      "vaileila"
    ]

      const [open, setOpen] = useState(false)
      const handleOpen = () => setOpen(true)
      const handleClose = () => setOpen(false)

      
      const style = {
        position: 'absolute',
        display: "flex",
        margin: 'auto',
        justifyContent: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: "rgba(237, 230, 219, 0.40)",
        border: '4px solid white',
        boxShadow: 24,
        p: 4
      };

   // GETTING SUBS

    const [subs, setSubs] = useState([])

      useEffect(() => {
        const getSubs = async () => {
            const res = await axios.get("/api/subtitles/")
            setSubs(res.data)
        }
        getSubs()
    }, [])

    const filmSubs = [...new Set(subs.filter(s => s.includes(movie.ids.export_name)))]

    return (
            <StreamWrap>
                <Header>Download</Header>

                <Grid component="img" src={"../logo/torr_logo_100.png"} title='Find torrent' onClick={handleOpen} sx={{...onlLogoSx, cursor: 'pointer' }} />
                <Modal
                  open={open}
                  onClose={handleClose}
                >
                  <Grid sx={style}>
                      {trackers.map(t => {
                        return (
                        <Link className="sublogo-mobile" href={t.path} target="_blank" rel='noopener noreferrer'>
                          <Grid component="img" src={"../logo/" + t.name + "_100.png"} title={t.name} sx={onlLogoSx} />
                        </Link>
                        )
                      })}
                  </Grid>
                </Modal>

               {filmSubs.map(sub => {

                  const subInfo = sub.replace(movie.ids.export_name, '').replace('.srt', '')
                  
                  function getLogo() {
                      
                      let tr = sub.replace(movie.ids.export_name, '')
                      tr = tr.slice(5, tr.length - 4)
                      
                      if (tr.includes("web") || tr.includes("blr") || tr.includes("dvd") || tr.includes("all")) {
                        tr = tr.slice(4, tr.length)
                      }

                      if (tr.includes("hdtv")) {
                        tr = tr.slice(5, tr.length)
                      }

                      if (tr.includes(".")) {
                      tr = tr.slice(0, tr.search(/\./) - 2)}
                      
                      let lastletter = tr.slice(tr.length - 1, tr.length)
                      if (lastletter.includes("_")) {
                        tr = tr.slice(0, tr.length - 1)
                      }

                      if (translators.includes(tr)) {
                         return "../translators/" + tr + "_logo150.png"
                      } else {
                          if (sub.includes("_rus")) {
                            return "../logo/sub_logo_rus70.png"
                          } else if (sub.includes("_eng")) {
                            return "../logo/sub_logo_eng70.png"
                          }
                      }
                  }
                  
                  const subLogo = getLogo()

                  return (
                    <Link className="sublogo" key={sub} href={subPath + sub} target="_blank" download>
                        <Grid component="img" src={subLogo} title={'sub' + subInfo} sx={onlLogoSx} />
                    </Link>
                  )
                }
               )}
            </StreamWrap>
    );
}

export default Downl;
