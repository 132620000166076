import { Grid, Link } from "@mui/material"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { onlLogoSx, coonSx } from "../../../theme";

const Streamingmobile = ({movie, isChecked}) => {

    const streamings = JSON.parse(movie.streaming)
    // console.log(streamings)
    
    return (
        <Grid item sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'left'}}>
            {isChecked && streamings.map((str) => {
                return(
                    <Link className='onllogo' key={str.id} href={str.url + str.link} target="_blank" rel="noopener noreferrer"> 
                        <Grid item sx={onlLogoSx}>
                        <LazyLoadImage
                          className="stream"
                          effect="blur"
                          placeholderSrc={"../logo/" + str.service + "_logo.png"}
                          src={"../logo/" + str.service + "_logo.png"}
                          height="100%"
                          width="100%"
                          style={{objectFit: "cover"}}
                          />
                        </Grid>
                    </Link>
                )
            })}
            {streamings.length !== 0 ? null : 
            <Grid 
                component="img" src={"../images/sad-coon-glasses60.png"} sx={coonSx}>
            </Grid>} 
        </Grid>
    );
}

export default Streamingmobile;
