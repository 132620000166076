import { useState, useRef } from 'react';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Grid, Card, CardMedia, CardContent, Stack, Switch } from "@mui/material";
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import Description from './DescriptionMobile';
import useOutsideClick from '../../../hooks/useOutsideClick';

export const Mainmoviecardmobile = ({movie, isChecked, setIsChecked}) => {

    const navigate = useNavigate()

    const posterPath = '../posters/'

    const ids = JSON.parse(movie.ids)
    const main = JSON.parse(movie.maininfo)
    const release = JSON.parse(movie.releaseinfo)
    const posters = JSON.parse(movie.posters)

    const [isShown, setIsShown] = useState(false);

    const posterSx = {
      position: "relative",
      height: "25vw", 
      maxHeight: 150,
      width: "16.8vw", 
      objectFit: "cover",
      maxWidth: 101,
      "&:hover": {
        zIndex: 100,
        transform: "scale(1.3) translate(7px, 0)",
        transition: "0.2s"
    }
    }

    const titleSx = {
      fontSize: "3.1vw",
      fontWeight: 700,
      lineHeight: 1.3,
      overflow: "hidden",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical"
  }

  
    const switchSx = {
      position: "absolute",
      alignSelf: "flex-end",
      width: 15,
      height: 15,
      padding: "14px",
      display: "flex",
      m: "0 5px 5px 0",
      '& .MuiSwitch-switchBase': {
          width: 26,
          height: 26,
          padding: "3px",
          transform: 'none',
          color: "transparent",
          ":hover": {
          bgcolor: "rgba(77, 182, 172, 0.1)"
          },
          '&.Mui-checked': {
              color: "transparent",
              transform: 'none',
              bgcolor: "rgba(77, 182, 172, 0.5)",
              '& .MuiSwitch-thumb:before': {
              backgroundImage: "url('../images/expand-after-light30.png')",
              },
              '& + .MuiSwitch-track': {
              opacity: 0.5
              },
          }
        },
      '& .MuiSwitch-thumb': {
        boxShadow: "0 0 2px 2px rgba(144, 164, 174, 0.5)",
        '&:before': {
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: "center",
          backgroundSize: 18,
          backgroundImage: "url('../images/expand_light30.png')",
        }
      }
    }

    const ref = useRef();

    useOutsideClick(ref, () => {
      setIsShown(false)
    });
    
    // console.log(ref)

    return (
            <Grid container item xs sx={{textAlign: "left"}}>
                  <Card sx={{overflow: "visible", display: "flex", bgcolor: "transparent", color: "primary.main", boxShadow: "unset"}}>
                      <div id={ids.cin_id} ref={ref}>
                      <CardMedia 

                        onTouchStart={() => setIsShown(true)}
                        onTouchEnd={() => setIsShown(false)}
                        onTouchMove={() => setIsShown(false)}
                        sx={posterSx}
                      >
                      
                      {isShown ?
                        <LazyLoadComponent
                        threshold={300}
                        >
                          <img src={posterPath + ids.export_name + '_1.jpg'}
                          alt=""
                          height="100%"
                          width="100%"
                          style={{
                            objectFit: "cover",
                            }}
                          />
                          <Description isShown={isShown} ids={ids} main={main} release={release} />
                          </LazyLoadComponent>
                      :
                      <LazyLoadImage
                          effect="blur"
                          placeholderSrc={posterPath + ids.export_name + '_' + posters.coon_default + '.jpg'}
                          src={posterPath + ids.export_name + '_' + posters.coon_default + '.jpg'}
                          height="100%"
                          width="100%"
                          style={{objectFit: "cover"}}
                          />
                      }
                      </CardMedia>
                      </div>
                      <CardContent
                      onTouchStart={() => setIsShown(false)}
                      onTouchEnd={() => setIsShown(false)}
                      sx={{width: "54vw", padding: "0 0 0 10px", "&:last-child": {padding: "0 0 0 10px"}}}
                      >
                        <Grid container sx={{height: "100%", justifyContent: "right"}}>
                            <Stack spacing={{xs:0}} sx={{width: "100%", height: "100%", justifyContent: "center"}}>
                                <Grid item sx={titleSx}>
                                    <RouterLink className='left' to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                                    {main.imdb_title_exp}
                                    </RouterLink>
                                </Grid>
                                <Grid item sx={{fontSize: "2.3vw", padding: "5px 0"}}>{main.imdb_director}</Grid> 
                                <Grid item sx={{fontSize: "2.3vw"}}>{main.imdb_genre}</Grid> 
                            </Stack>
                            <Switch 
                                id={ids.cin_id}
                                checked={isChecked} onChange={() => {setIsChecked((prev) => !prev)}}
                                sx={switchSx}> 
                            </Switch>
                        </Grid>
                      </CardContent>
                  </Card>
            </Grid>
    );
}
