import {useState} from "react"
import { Typography, Grid, Box } from "@mui/material";
import { Mainmoviecard } from "./main/MainMovieCard";
import { Mainmoviecardmobile } from "./main/MainMovieCardMobile";
import Streaming from "./main/Streaming"
import Download from "./main/Download";
import Rates from "./main/Rates";
import Streamcollapse from "./main/StreamCollapse";
import useWindowDimensions from "./../../functions/windowDimensions"
import { textureSx } from "../../theme";
import Ratesmobile from "./main/RatesMobile";


function Main({movie, tr_names}) {

    // const ids = JSON.parse(movie.ids)

    const { clientWidth } = useWindowDimensions();
    const [isChecked, setIsChecked] = useState(false);

    const mainSx = {
        padding: "0", 
        m: "9px auto 0", 
        color: "primary.main", 
        width: {xs: "100%", md: 960},
        bgcolor: "rgba(237, 230, 219, 0.06)",
        // bgcolor: "rgba(236, 239, 241, 0.95)",
    }
    


    return(
      <Typography component="span" variant="body1"> 
      <Box sx={{textAlign: "center"}}>

      {(clientWidth > 600) ? 

      <Grid container sx={{...mainSx, ...textureSx}}>

              <Mainmoviecard movie={movie}/>
              <Rates movie={movie}/>
              <Grid container item xs sx={{display: {xs: "none", sm: "flex"}, paddingLeft: "10px", alignItems: "center", justifyContent: "center", width: {xs: "17.1875vw", md: 165}}}>
                    <Streaming movie={movie} />
               </Grid>
              <Grid container item sx={{display: {xs: "none", sm: "flex"}, alignItems: "center", justifyContent: "center", width: {xs: "17.1875vw", md: 165}}}>
                    <Download m={movie} tr_names={tr_names}/>
              </Grid>

      </Grid>

      :

      <Grid container sx={{...mainSx, ...textureSx}}>

              <Mainmoviecardmobile movie={movie} isChecked={isChecked} setIsChecked={setIsChecked}/>
              <Ratesmobile movie={movie}/>
              <Streamcollapse movie={movie} isChecked={isChecked} tr_names={tr_names}/>

      </Grid>
      }

          </Box>
      </Typography>
    )
}

export default Main